import React from 'react';
import ProjectStatus from "../ProjectStatus";
import ProjectDetailsAirlock from "./ProjectDetailsAirlock";
import ImageCard from "../../Extras/imageCard";
import IMAGES from "../../../images/images";
import Contacts from "../../Extras/Contacts";

function MarsChamber(props) {
    return (
        <div>
            <section id="MarsChamber">
                <div className="main-title-airlock">
                    <ProjectStatus ongoing={true}/>
                    <h1> Mars Chamber </h1>
                    <h3><i>Test for Mars, on Earth.</i></h3>
                </div>
            </section>

            <section id="sec-the-idea">
                <div className="ideaInformations-airlock">
                    <h1 className="idea-title">THE IDEA</h1>
                    <div>
                        <p className="idea-text">The worst time to test your expensive new technology for the first time is when its on a planet 250 million kilometers away with no hope of any repairs if anything is wrong. Being able to test small-scale technologies in the Martian atmosphere but in the comfort of your home planet is vital to making sure the developed technology works as intended when it gets to the lonely Red Planet. Our team is currently (2023) building a Mars Atmosphere Simulation Chamber (MASC) for this express purpose, to be used by the team in future experiments. Our chamber aims to simulate the pressure, temperature, and atmospheric conditions on Mars in a safe-sized chamber.</p>
                    </div>
                </div>
            </section>

            {/*<div className="title-seperator">*/}
            {/*    <h1>The Competition</h1>*/}
            {/*</div>*/}

            {/*<section id="sec-details">*/}
            {/*    <ProjectDetailsAirlock/>*/}
            {/*</section>*/}

            {/*<div className="title-seperator">*/}
            {/*    <h1>Gallery</h1>*/}
            {/*</div>*/}
            {/*<section id="sec-gallery">*/}
            {/*    <div className="gallery-container">*/}
            {/*        <ImageCard image={IMAGES.marsChamber}/>*/}
            {/*        <ImageCard image={IMAGES.marsChamber}/>*/}
            {/*        <ImageCard image={IMAGES.marsChamber}/>*/}

            {/*        <ImageCard image={IMAGES.marsChamber}/>*/}
            {/*        <ImageCard image={IMAGES.marsChamber}/>*/}
            {/*        <ImageCard image={IMAGES.marsChamber}/>*/}
            {/*        <ImageCard image={IMAGES.marsChamber}/>*/}
            {/*        <ImageCard image={IMAGES.marsChamber}/>*/}
            {/*        <ImageCard image={IMAGES.marsChamber}/>*/}
            {/*    </div>*/}
            {/*</section>*/}

            <div className="contact-us">
                <Contacts bgcolor={"red-footer"}/>
            </div>

            <div className="text-center p-3 text-white" style={{backgroundColor: "black"}}>
                © 2022 Copyright: UBCmarscolony.com
            </div>
        </div>
    );
}

export default MarsChamber;