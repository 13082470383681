import React from 'react';
import "./sabatier-style.css";
import items from "./sabatierTimelineItems";
import {Chrono} from "react-chrono";
import ProjectDetails from "../ProjectDetails";
import Contacts from "../../Extras/Contacts";
import {Badge} from "react-bootstrap";


const iteems = [
    { title: 'Timeline title 1', cardTitle: 'Card Title 1' },
    { title: 'Timeline title 2', cardTitle: 'Card Title 2' },
];

function SabatierPage(props) {
    return (
        <div>
            <section id="sec-sabatier-title">
                <div className="main-title">
                    <div>
                        <h4>
                            <Badge pill bg="success">Ongoing-Project</Badge>
                        </h4>
                    </div>
                    <h1> SABATIER <br/> FUEL PLANT</h1>
                    <h3><i>The quest to make fuel on Mars</i></h3>
                </div>
            </section>

            <section id="sec-the-idea">
                <div className="ideaInformations">
                    <h1 className="idea-title">THE IDEA</h1>
                    <p className="idea-text">The unique atmospheric and geological features on Mars allow for in-situ
                        production of methalox (liquid methane-liquid oxygen) propellant, which would make Mars return
                        missions more feasible. Methalox is easier to handle and store compared to conventional liquid
                        hydrogen propellant. For example, SpaceX has unveiled plans for a reusable launch vehicle,
                        Starship, and the accompanying Mars mission architecture, which relies on in-situ propellant
                        production to supply fuel for the trip back to Earth. Before such a system can be deployed on
                        Mars, a feasibility study and rigorous tests at smaller scales must be conducted on Earth.</p>
                </div>
            </section>

            <div className="title-seperator">
                <h1>Details</h1>
            </div>

            <section id="sec-details">
                <ProjectDetails/>
            </section>

            <div className="title-seperator">
                <h1>Project Timeline</h1>
            </div>


            <Chrono
                cardWidth={400}
                items={items}
                mode={"VERTICAL_ALTERNATING"}
                useReadMore={true}
                hideControls={"true"}
                mediaSettings={{ align: 'right', fit: 'contain' }}
                theme={{
                    primary: '#bb3b3b',
                    secondary: 'white',
                    cardBgColor: 'white',
                    cardForeColor: 'black',
                    titleColor: 'black',
                    titleColorActive: '#bb3b3b',
                }}
            />

            <div className="text-center p-3 text-white" style={{ backgroundColor: "black" }}>
                © 2023 Copyright: UBCmarscolony.com
            </div>
        </div>
    );
}

export default SabatierPage;