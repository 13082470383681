import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";


import { Link } from "react-router-dom";

import { Styled } from "../Extras/styles";
import IMAGES from "../../images/images.js";
import ApplyButton from "../Not Used/applyButton.js";
import Offcanvas from 'react-bootstrap/Offcanvas';

const NavBar = (props) => {

  return (
      <>
        {['md'].map((expand) => (
            <Navbar key={expand} expand={expand} className="mb-3 p-3 justify-center"
                    fixed={"top"} variant={"dark"} bg={"dark"}>
              <Container fluid>
                {/* <Navbar.Brand href="/" className="nav-brand"> */}
                    <img src={IMAGES.navLogo} className="nav-logo" alt="logo" />

                    {/* <h2 className="navbar-logo">UBC MARS COLONY</h2> */}
                {/* </Navbar.Brand> */}
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-${expand}`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                    placement="end"
                >
                  <Offcanvas.Header closeButton>
                    {/* <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}> */}
                      {/* UBC Mars Colony */}
                    {/* </Offcanvas.Title> */}
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                      <Nav className="align-items-center gap-5 justify-content-end flex-grow-1 pe-3">
                          <Nav.Link
                              href="/"
                              className="coolLink"
                          >
                              Home
                          </Nav.Link>

                          <Nav.Link href="/about"
                          className="coolLink">About</Nav.Link>

                          <Nav.Link href="/projects"
                                    className="coolLink">Projects</Nav.Link>

                          <Nav.Link href="/sponsors"
                                    className="coolLink">Sponsors</Nav.Link>

                          {/*Uncomment these  when recruitment opens and change
                          the link as well as the one found in HomeTextPart*/}
                          {/* <ApplyButton
                             text={"Apply"}
                                 target_link=
                                     "https://ubc.ca1.qualtrics.com/jfe/form/SV_5zL4WGSMOouKCnI"/> */}
                      </Nav>


                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
        ))}
      </>
  );
};

export default Styled(NavBar);
