import React from 'react';
import CustomButton from "../Extras/CustomButton";
import ProjectStatus from "./ProjectStatus";

function FeaturedProject(props) {
    if (props.reversed) {
        return (<div className="proj-reversed">
            <div className="proj-images">
                <img className="img-project" src={props.ImageLink}/>
            </div>
            <div className="proj-text">
                <div className="proj-titles">
                    <ProjectStatus ongoing = {props.ongoing}/>
                    <h1>{props.Title}</h1>
                    <h5>{props.Subtitle}</h5>
                </div>
                <div className="proj-description">
                    <hr/>
                    <p>{props.desc}</p>
                    <CustomButton text={"Learn more"}
                                  color={"red"}
                                  target_link={props.link}/>
                </div>
            </div>

        </div>);
    }
    return (
        <div className="proj">
            <div className="proj-images">
                <img className="img-project" src={props.ImageLink}/>
            </div>
            <div className="proj-text">
                <div className="proj-titles">
                    <ProjectStatus ongoing = {props.ongoing}/>
                    <h1>{props.Title}</h1>
                    <h5>{props.Subtitle}</h5>
                </div>
                <div className="proj-description">
                    <hr/>
                    <p>{props.desc}</p>
                    <CustomButton text={"Learn more"}
                                  color={"red"}
                                  target_link={props.link}/>
                </div>
            </div>
        </div>
    );
}

export default FeaturedProject;