import React, { useState } from "react";
import { Link, BrowserRouter as Router } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import "./Banner.css";
const Banner = (props) => {
  const [subMenuOpen, setSubMenuOpen] = useState(-1);
  const toggleMenu = (x) => setSubMenuOpen(subMenuOpen === x ? -1 : x);

  return (
    <div className="container mt-5">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="column justify-content-center"
      >
        <div
          style={{
            maxHeight: subMenuOpen !== 0 ? 100 : undefined,
            width: "100%",
          }}
          className="submenu-list col-xl-5 col-lg-5 my-2"
        >
          <div>
            <ul>
              <li className="submenu-headings">
                <Link
                  to="#"
                  onClick={() => toggleMenu(0)}
                  style={{ color: "white" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    Do I have to be an engineering student <br></br> to apply?{" "}
                    <FontAwesomeIcon
                      className="text-white"
                      icon={subMenuOpen === 0 ? faMinus : faPlus}
                    />
                  </div>
                </Link>
              </li>
            </ul>
          </div>
          <ul class={`sub-menu ${subMenuOpen === 0 ? "is-open" : ""}`}>
            <li style={{ fontSize: 15 }} class="menu-item">
              No. We consider people from all backgrounds, the most important
              requirement is an eagerness to learn!
            </li>
          </ul>
        </div>

        <div
          style={{
            maxHeight: subMenuOpen !== 1 ? 100 : undefined,
            width: "100%",
            marginLeft: 0,
          }}
          className="submenu-list col-xl-5 col-lg-5 my-2"
        >
          <ul>
            <li className="submenu-headings">
              <Link
                to="#"
                onClick={() => toggleMenu(1)}
                style={{ color: "white" }}
              >
                Do we get paid?{" "}
                <FontAwesomeIcon
                  className="text-white"
                  icon={subMenuOpen === 1 ? faMinus : faPlus}
                />
              </Link>
            </li>
          </ul>
          <ul class={`sub-menu ${subMenuOpen === 1 ? "is-open" : ""}`}>
            <li style={{ fontSize: 15 }} class="menu-item">
              No. We are a passionate group of volunteers.
            </li>
          </ul>
        </div>

        <div
          style={{
            maxHeight: subMenuOpen !== 2 ? 100 : undefined,
            width: "100%",
          }}
          className="submenu-list col-xl-5 col-lg-5 my-2"
        >
          <ul>
            <li className="submenu-headings">
              <Link
                to="#"
                onClick={() => toggleMenu(2)}
                style={{ color: "white" }}
              >
                Can I apply even though I'm in first year?{" "}
                <FontAwesomeIcon
                  className="text-white"
                  icon={subMenuOpen === 2 ? faMinus : faPlus}
                />
              </Link>
            </li>
          </ul>
          <ul class={`sub-menu ${subMenuOpen === 2 ? "is-open" : ""}`}>
            <li style={{ fontSize: 15 }} class="menu-item">
              Yes, first years are welcome to apply, there are first years who
              successfully join UBC Mars Colony every year.
            </li>
          </ul>
        </div>

        <div
          style={{
            maxHeight: subMenuOpen !== 3 ? 100 : undefined,
            width: "100%",
          }}
          className="submenu-list col-xl-5 col-lg-5 my-2"
        >
          <ul>
            <li className="submenu-headings">
              <Link
                to="#"
                onClick={() => toggleMenu(3)}
                style={{ color: "white" }}
              >
                When are you open for applications?{" "}
                <FontAwesomeIcon
                  className="text-white"
                  icon={subMenuOpen === 3 ? faMinus : faPlus}
                />
              </Link>
            </li>
          </ul>
          <ul class={`sub-menu ${subMenuOpen === 3 ? "is-open" : ""}`}>
            <li style={{ fontSize: 15 }} class="menu-item">
              We have 2 recruitment sessions, one in September and the other in
              January. But we are always accepting applications. Please feel
              free to send to ubcmarscolony.@gmail.com
            </li>
          </ul>
        </div>

        <div
          style={{
            maxHeight: subMenuOpen !== 4 ? 100 : undefined,
            width: "100%",
          }}
          className="submenu-list col-xl-5 col-lg-5 my-2"
        >
          <ul>
            <li className="submenu-headings">
              <Link
                to="#"
                onClick={() => toggleMenu(4)}
                style={{ color: "white" }}
              >
                Can I join the team even though I'm not a UBC student?{" "}
                <FontAwesomeIcon
                  className="text-white"
                  icon={subMenuOpen === 4 ? faMinus : faPlus}
                />
              </Link>
            </li>
          </ul>
          <ul class={`sub-menu ${subMenuOpen === 4 ? "is-open" : ""}`}>
            <li style={{ fontSize: 15 }} class="menu-item ">
              “Yes. However, this is limited to non-hands on activities. This is
              because UBC APSC is not able to grant the same safety insurance
              which typical UBC student members receive after their training.
              Activities such as simulations, CAD designs, and coding are
              allowed.
            </li>
          </ul>
        </div>

        <div
          style={{
            maxHeight: subMenuOpen !== 5 ? 100 : undefined,
            width: "100%",
          }}
          className="submenu-list col-xl-5 col-lg-5 my-2"
        >
          <ul>
            <li className="submenu-headings">
              <Link
                to="#"
                onClick={() => toggleMenu(5)}
                style={{ color: "white" }}
              >
                How big is your team?{" "}
                <FontAwesomeIcon
                  className="text-white"
                  icon={subMenuOpen === 5 ? faMinus : faPlus}
                />
              </Link>
            </li>
          </ul>
          <ul class={`sub-menu ${subMenuOpen === 5 ? "is-open" : ""}`}>
            <li style={{ fontSize: 15 }} class="menu-item ">
              We have around 30 members in our whole team divided into smaller
              sub-teams.
            </li>
          </ul>
        </div>

        <div
          style={{
            maxHeight: subMenuOpen !== 6 ? 100 : undefined,
            width: "100%",
          }}
          className="submenu-list col-xl-5 col-lg-5 my-2"
        >
          <ul>
            <li className="submenu-headings">
              <Link
                to="#"
                onClick={() => toggleMenu(6)}
                style={{ color: "white" }}
              >
                Are you affiliated with UBC rocket?{" "}
                <FontAwesomeIcon
                  className="text-white"
                  icon={subMenuOpen === 6 ? faMinus : faPlus}
                />
              </Link>
            </li>
          </ul>
          <ul class={`sub-menu ${subMenuOpen === 6 ? "is-open" : ""}`}>
            <li style={{ fontSize: 15 }} class="menu-item">
              No. Although we do share some facilities with UBC Rocket, we are
              completely separate organizations.
            </li>
          </ul>
        </div>

        <div
          style={{
            maxHeight: subMenuOpen !== 7 ? 100 : undefined,
            width: "100%",
          }}
          className="submenu-list col-xl-5 col-lg-5 my-2"
        >
          <ul>
            <li className="submenu-headings">
              <Link
                to="#"
                onClick={() => toggleMenu(7)}
                style={{ color: "white" }}
              >
                How much time commitment?{" "}
                <FontAwesomeIcon
                  className="text-white"
                  icon={subMenuOpen === 7 ? faMinus : faPlus}
                />
              </Link>
            </li>
          </ul>
          <ul class={`sub-menu ${subMenuOpen === 7 ? "is-open" : ""}`}>
            <li style={{ fontSize: 15 }} class="menu-item">
              We have 5 hour minimum commitment in-person for general team
              members every Saturday.
            </li>
          </ul>
        </div>

        <div
          style={{
            maxHeight: subMenuOpen !== 8 ? 100 : undefined,
            width: "100%",
          }}
          className="submenu-list col-xl-5 col-lg-5 my-2"
        >
          <ul>
            <li className="submenu-headings">
              <Link
                to="#"
                onClick={() => toggleMenu(8)}
                style={{ color: "white" }}
              >
                What positions are you recruiting for?{" "}
                <FontAwesomeIcon
                  className="text-white"
                  icon={subMenuOpen === 8 ? faMinus : faPlus}
                />
              </Link>
            </li>
          </ul>
          <ul class={`sub-menu ${subMenuOpen === 8 ? "is-open" : ""}`}>
            <li style={{ fontSize: 15 }} class="menu-item">
              The available opportunities are always changing, so please check
              back often on our social media and website.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Banner;

/*import Faq from "react-faq-component";

const data = {
    title: "FAQ (How it works)",
    rows: [
        {
            title: "Lorem ipsum dolor sit amet,",
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed tempor sem. Aenean vel turpis feugiat,
              ultricies metus at, consequat velit. Curabitur est nibh, varius in tellus nec, mattis pulvinar metus.
              In maximus cursus lorem, nec laoreet velit eleifend vel. Ut aliquet mauris tortor, sed egestas libero interdum vitae.
              Fusce sed commodo purus, at tempus turpis.`,
        },
        {
            title: "Nunc maximus, magna at ultricies elementum",
            content:
                "Nunc maximus, magna at ultricies elementum, risus turpis vulputate quam, vitae convallis ex tortor sed dolor.",
        },
        {
            title: "Curabitur laoreet, mauris vel blandit fringilla",
            content: `Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc, ac sagittis leo elit vel lorem.
            Fusce tempor lacus ut libero posuere viverra. Nunc velit dolor, tincidunt at varius vel, laoreet vel quam.
            Sed dolor urna, lobortis in arcu auctor, tincidunt mattis ante. Vivamus venenatis ultricies nibh in volutpat.
            Cras eu metus quis leo vestibulum feugiat nec sagittis lacus.Mauris vulputate arcu sed massa euismod dignissim. `,
        },
        {
            title: "What is the package version",
            content: <p>current version is 1.2.1</p>,
        },
    ],
};

const styles = {
    bgColor: "#a83939",
    titleTextColor: "black",
    rowTitleColor: "white",
    rowContentTextSize: '16px',
    rowContentColor: 'grey',
    arrowColor: "black",
    titleTextSize: '30px',
        // rowTitleTextSize: 'medium',
        // rowContentColor: 'grey',
      
        // rowContentPaddingTop: '10px',
        rowContentPaddingBottom: '10px',
        rowContentPaddingLeft: '50px',
        // rowContentPaddingRight: '150px',
        // arrowColor: "red",
      
};

const config = {
    animate: true,
    arrowIcon: "V",
    openOnload: 0,
    expandIcon: "+",
    collapseIcon: "-",
};



const Banner = () => {
    return (
        <div>
            <div>
            <Faq
                data={data}
                styles={styles}
                config={config}
            />
        </div> 
        </div>
    );
};

export default Banner;


      
</div>

*/
