import React from 'react';
import './custom-button.css'

function CustomButton(props) {
    return (
            <a target={props.target} href={props.target_link}>
                <button href="#aboutBreakSec" className={`custom-btn btn-14 ${props.color}`}>
                    {props.text}
                </button>
            </a>
    );
}

export default CustomButton;