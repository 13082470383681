import React from 'react';
import IMAGES from "../../images/images";

function SponsorsSlider(props) {
    return (
        <div
            className="container overflow-hidden p-12 relative">
            <div className={`flex animate absolute left-0 ${props.direction}`}>
                <div className="sponsors-slide logo">
                    <a><img src={IMAGES.sponsor_1} alt=""/></a>
                    <a><img src={IMAGES.sponsor_2} alt=""/></a>
                    <a><img src={IMAGES.sponsor_3} alt=""/></a>
                    <a><img src={IMAGES.sponsor_4} alt=""/></a>
                    <a><img src={IMAGES.sponsor_5} alt=""/></a>
                    <a><img src={IMAGES.sponsor_6} alt=""/></a>
                    <a><img src={IMAGES.sponsor_8} alt=""/></a>
                    <a><img src={IMAGES.sponsor_9} alt=""/></a>
                    <a><img src={IMAGES.sponsor_7} alt=""/></a>
                    <a><img src={IMAGES.sponsor_10} alt=""/></a>
                    <a><img src={IMAGES.sponsor_11} alt=""/></a>
                    <a><img src={IMAGES.sponsor_1} alt=""/></a>
                    <a><img src={IMAGES.sponsor_2} alt=""/></a>
                    <a><img src={IMAGES.sponsor_3} alt=""/></a>
                    <a><img src={IMAGES.sponsor_4} alt=""/></a>
                    <a><img src={IMAGES.sponsor_5} alt=""/></a>
                    <a><img src={IMAGES.sponsor_6} alt=""/></a>
                    <a><img src={IMAGES.sponsor_7} alt=""/></a>
                    <a><img src={IMAGES.sponsor_8} alt=""/></a>
                    <a><img src={IMAGES.sponsor_9} alt=""/></a>
                    <a><img src={IMAGES.sponsor_10} alt=""/></a>
                    <a><img src={IMAGES.sponsor_11} alt=""/></a>
                </div>
            </div>
        </div>
    );
}

export default SponsorsSlider;
