import React from "react";
import "./Spinner.css";
import spinner from "../../images/spinner.png";
function Spinner() {
    return (
        <div style={{ flex: 1 }}>
            <div class="loaderMain">
        <span class="loader1">
          <img src={spinner} class="img1" alt="brand" />
        </span>
            </div>
        </div>
    );
}
export default Spinner;