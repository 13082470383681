// react
import React, { useState, useEffect } from "react";
// Using ES6 import syntax
import 'normalize.css/normalize.css';

// components
import Navbar from "./components//Nav Bar/NavBar";
import Spinner from "./components/Spinner/Spinner";
import Home from "./components//Home Page/Home";
import Projects from "./components/Projects/Projects";
import About from "./components/About Us/About";
import Sponserships from "./components/Sponsorships/Sponserships";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
// react-router
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
// styles

import "bootstrap/dist/css/bootstrap.min.css";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "../src/styles";
import "../src/styles.css";
import "./App.css";
import SabatierPage from "./components/Projects/Sub Projects/SabatierPage";
import FarmingPage from "./components/Projects/Sub Projects/FarmingPage";
import AirlockPage from "./components/Projects/Sub Projects/AirlockPage";
import HeliosPage from "./components/Projects/Sub Projects/HeliosPage";
import MarsChamber from "./components/Projects/Sub Projects/MarsChamber";

const theme = {
  light: {
    mainColor: "black",
    backgroundColor: "white",
  },
};


function App() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1200);
  }, []);

  const [currentTheme] = useState("light");

  //  useEffect(() => {
  //   const timer = setTimeout(() => {
  //     upadateLoad(false);
  //   }, 1200);

  //   return () => clearTimeout(timer);
  // }, []);

  return (
    <Router>
      <div className="App">
        {loading ? (
          <Spinner />
        ) : (
          <ThemeProvider theme={theme[currentTheme]}>
            <GlobalStyle />
            <Navbar currentTheme={currentTheme} />

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/about" element={<About />} />

              <Route path="/sponsors" element={<Sponserships />} />
              <Route path="/sabatier" element={<SabatierPage />} />
              <Route path="/farming" element={<FarmingPage />} />
              <Route path="/airlock" element={<AirlockPage />} />
              <Route path="/helios" element={<HeliosPage />} />
              <Route path="/chamber" element={<MarsChamber/>} />
            </Routes>
          </ThemeProvider>
        )}
      </div>
    </Router>
  );
}

export default App;
