import React from "react";
import { SiGmail} from 'react-icons/si';

import "./TeamMem.css";

export default function TeamMembers() {
  return (
    <div>
      <>
        <div className="container">
          <div style={{ justifyContent: "center" }} className="row">

            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="team-card">
                <div className="team-member-img">
                  <img
                      src={require("../../images/Alyona_Headshot_Square.jpeg")}
                      className="img-fluid"

                  />
                  <div className="member-social">
                    <i className="fa-solid fa-share-nodes"></i>
                    <ul className="social-profile list-style style1">
                      <li>
                        <a target="_blank" href="mailto:startrek@student.ubc.ca">
                          <i className="fa fa-envelope"></i>
                        </a>
                      </li>
                      <li>
                        {/* <a target="_blank" href="https://twitter.com/">
                          <i class="fa-brands fa-twitter"></i>
                        </a> */}
                      </li>
                      <li>
                        <a target="_blank" href="https://www.linkedin.com/in/alyona-glazyrina/">
                          <i className="fa-brands fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-member-imfo">
                  <h3>Alyona </h3>
                  <span> Captain</span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="team-card">
                <div className="team-member-img">
                  <img
                    src={require("../../images/Dagan_Headshot.png")}
                    className="img-fluid"
                   
                  />
                  <div className="member-social">
                    <i class="fa-solid fa-share-nodes"></i>
                    <ul className="social-profile list-style style1">
                    <li>
                        <a target="_blank" href="mailto:ack4@student.ubc.ca">
                          <i class="fa fa-envelope"></i>
                        </a>
                      </li>
                      <li>
                        {/* <a target="_blank" href="https://twitter.com/">
                          <i class="fa-brands fa-twitter"></i>
                        </a> */}
                      </li>
                      <li>
                        <a target="_blank" href="https://ca.linkedin.com/in/dagan-schoen-a942ab67">
                          <i class="fa-brands fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-member-imfo">
                  <h3>Dagan </h3>
                  <span> Captain</span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="team-card">
                <div className="team-member-img">
                  <img
                    src={require("../../images/Thanooshan_Headshot_Square.jpeg")}
                    alt="Image"
                    className="img-fluid"
                  />
                  <div className="member-social">
                    <i class="fa-solid fa-share-nodes"></i>
                    <ul className="social-profile list-style style1">
                    <li>
                        <a target="_blank" href="mailto:thanoo@student.ubc.ca">
                          <i class="fa fa-envelope"></i>
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://ca.linkedin.com/in/thanooshan-satheeshkumar">
                          <i class="fa-brands fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-member-imfo">
                  <h3>Thanooshan </h3>
                  <span>Operations Lead</span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="team-card">
                <div className="team-member-img">
                  <img
                    src={require("../../images/Justin_Headshot_Square.jpeg")}
                    alt="Image"
                    className="img-fluid"
                  />
                  <div className="member-social">
                    <i class="fa-solid fa-share-nodes"></i>
                    <ul className="social-profile list-style style1">
                     
                      <li>
                       
                      </li>
                      
                      <li>
                        <a target="_blank" href="mailto:jng2002@student.ubc.ca">
                          <i class="fa fa-envelope"></i>
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://ca.linkedin.com/in/ngjstn">
                          <i class="fa-brands fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-member-imfo">
                  <h3>Justin </h3>
                  <span>Elec Lead</span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="team-card">
                <div className="team-member-img">
                  <img
                    className="img-fluid"
                    src={require("../../images/Caleb_Headshot.png")}
                    alt="Image"
                  />
                  <div className="member-social">
                    <i class="fa-solid fa-share-nodes"></i>
                    <ul className="social-profile list-style style1">
                    <li>
                        <a target="_blank" href="mailto:creynard@student.ubc.ca">
                          <i class="fa fa-envelope"></i>
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://ca.linkedin.com/in/calebreynard">
                          <i class="fa-brands fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-member-imfo">
                  <h3>Caleb</h3>
                  <span>Chem Lead</span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="team-card">
                <div className="team-member-img">
                  <img
                    className="img-fluid"
                    src={require("../../images/Casey_Headshot_Square.jpeg")}
                  />
                  <div className="member-social">
                    <i class="fa-solid fa-share-nodes"></i>
                    <ul className="social-profile list-style style1">
                    <li>
                        <a target="_blank" href="mailto:caseyek@student.ubc.ca
">
                          <i class="fa fa-envelope"></i>
                        </a>
                      </li>
                      <li>
                        {/* <a target="_blank" href="https://twitter.com/">
                          <i class="fa-brands fa-twitter"></i>
                        </a> */}
                      </li>
                      <li>
                        <a target="_blank" href="https://ca.linkedin.com/in/caseykrombein">
                          <i class="fa-brands fa-linkedin-in"></i>
                        </a>
                      </li>
                     
                    </ul>
                  </div>
                </div>
                <div className="team-member-imfo">
                  <h3>Casey </h3>
                  <span> Mech Lead</span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="team-card">
                <div className="team-member-img">
                  <img
                    className="img-fluid"
                    src={require("../../images/Douglas_Headshot_Square.jpeg")}
                  />
                  <div className="member-social">
                    <i class="fa-solid fa-share-nodes"></i>
                    <ul className="social-profile list-style style1">
                    <li>
                        <a target="_blank" href="mailto:dzhu2003@student.ubc.ca">
                          <i class="fa fa-envelope"></i>
                        </a>
                      </li>
                    
                      <li>
                        <a target="_blank" href="https://ca.linkedin.com/in/douglas-zhu-518a3a268">
                          <i class="fa-brands fa-linkedin-in"></i>
                        </a>
                      </li>
                     
                    </ul>
                  </div>
                </div>
                <div className="team-member-imfo">
                  <h3>Douglas </h3>
                  <span> Mech Lead</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
